import React  from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useCampaign } from '../Campaign/context';
import Link from 'next/link';

// Dynamic imports
const Slider = dynamic(() => import('react-slick'));
const Images = dynamic(() => import('./Images'));
const Arrow = dynamic(() => import('../Arrow'));

const Slides = ({ carousel }) => {
  const s = require('./slides.module.scss');
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    centerPadding: '20px',
    nextArrow: <Arrow right />,
    prevArrow: <Arrow reverse />
  };

  const { showCampaign } = useCampaign();
  const campaignItem = {
    images: {
      focal_height: null,
      focal_width: null,
      large: "/assets/vup-billboard.jpg",
      medium: "/assets/vup-billboard.jpg",
      small: "/assets/vup-billboard.jpg"
    }
  };

  return (
    <div className={s.Slider}>
      <Slider {...settings}>
        <div className={s.Container} key={`slides_00`}>
          <a onClick={showCampaign}>
            <div className={s.Content}>
              <div className={s.Category}>
                <div>
                  <p>Competition</p>
                </div>
              </div>
              <div className={s.title}>
                <h1><span>Pop & Win Big with V-UP<br />This Summer</span></h1>
              </div>
            </div>
            <Images
              style={{ width: '1920px', height: '1080px' }}
              {...campaignItem}
            />
          </a>
        </div>
        {carousel && carousel.length > 0 && carousel.map((item, index) => (
          <div className={s.Container} key={`slides_${index}`}>
            <Link href={`article${item.button.link}`} passHref>
              <a>
                <div className={s.Content}>
                  <div className={s.Category}>
                    <div>
                      <p>{item.date}</p>
                    </div>
                  </div>
                  <div className={s.title}>
                    <h1><span>{item.title}</span></h1>
                  </div>
                </div>
                <Images
                  style={{ width: '1920px', height: '1080px' }}
                  {...item}
                />
              </a>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Slides.propTypes = {
  carousel: PropTypes.array,
};

export default Slides;
