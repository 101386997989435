import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const CampaignContext = createContext();

// Campaign Provider Component
export const CampaignProvider = ({ children }) => {
  const [campaignVisible, setCampaignVisible] = useState(false);

  // Function to set the campaign as seen for the current date
  const seenCampaign = () => {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    localStorage.setItem('campaignLastShown', todayString);
  };

  // Function to close the campaign and store the current date
  const closeCampaign = () => {
    setCampaignVisible(false);
    seenCampaign();
  };

  // Function to reset campaign visibility manually
  const showCampaign = () => {
    setCampaignVisible(true);
  };

  // Effect to check visibility on component mount
  useEffect(() => {
    const lastShown = localStorage.getItem('campaignLastShown');
    const now = new Date();
    const todayString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

    if (lastShown !== todayString) {
      setCampaignVisible(true);
    }
  }, []);

  return (
    <CampaignContext.Provider
      value={{ campaignVisible, closeCampaign, showCampaign, seenCampaign }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

// Custom Hook to use the Campaign Context
export const useCampaign = () => {
  return useContext(CampaignContext);
};
