import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Slides = dynamic(() => import('../Slides'));
const FeaturedArticles = dynamic(() => import('../FeatureArticle'));
const IsotopeContainer = dynamic(() => import('../Isotope/IsotopeContainer'));
const IsotopeBlock = dynamic(() => import('../Isotope/IsotopeBlock'));
const MasonryLayout = dynamic(() => import('../Masonry'));

function ArticleListing(props) {
  const router = useRouter();
  const { carousel, list, blurb, featured_article, title } = props.info;
  const { show_category, featured_category } = props;
  const checkPath = router.asPath;

  useEffect(() => {
    utag.view();
  }, []);

  /**
   * Check for previous data, if it existes the listing array will be switched
   */
  const articleListing =
    Array.isArray(props.data) && props.data.length > 0 ? props.data : list;

  const s = require('../../theme/pages.module.scss');

  let meta_info = [];
  let meta_info_og = [];

  const TWQ = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
                a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                // Insert Twitter Pixel ID and Standard Event data below
                twq('init','nujyj');
                twq('track','PageView');`;

  /*
   * meta info for the page
   */
  if (props.meta && props.meta.meta_title && props.meta.meta_title === '') {
    meta_info = [
      { name: 'title', content: title },
      { name: 'description', content: blurb },
      { name: 'keywords', content: description },
    ];
  } else {
    meta_info = [
      {
        name: 'title',
        content:
          props.meta && props.meta.meta_title ? props.meta.meta_title : '',
      },
      {
        name: 'description',
        content:
          props.meta && props.meta.meta_description
            ? props.meta.meta_description
            : '',
      },
      {
        name: 'keywords',
        content:
          props.meta && props.meta.meta_keywords
            ? props.meta.meta_keywords
            : '',
      },
    ];
  }

  /*
   *if og tags push into meta array ^^
   */
  if (props.meta && props.meta.og) {
    meta_info_og.push(
      {
        property: `og:title`,
        content: props.meta && props.meta.og.title ? props.meta.og.title : '',
      },
      {
        property: `og:description`,
        content:
          props.meta && props.meta.og.description
            ? props.meta.og.description
            : '',
      },
      {
        property: `og:image`,
        content: props.meta && props.meta.og.image ? props.meta.og.image : '',
      },
      {
        property: `og:type`,
        content: props.meta && props.meta.og.type ? props.meta.og.type : '',
      },
      {
        property: `og:url`,
        content: props.meta && props.meta.og.title ? props.meta.og.title : '',
      },
      {
        property: 'twitter:title',
        content: props.meta && props.meta.og.title ? props.meta.og.title : '',
      },
      {
        property: 'twitter:description',
        content:
          props.meta && props.meta.og.description
            ? props.meta.og.description
            : '',
      },
      {
        property: 'twitter:image',
        content: props.meta && props.meta.og.image ? props.meta.og.image : '',
      },
      { name: 'twitter:card', content: 'summary_large_image' }
    );
  }

  return (
    <div className={checkPath.includes('search') ? s.SearchWrapper : s.Wrapper}>
      <Head>
        <title>
          {props.meta && props.meta.meta_title
            ? props.meta.meta_title
            : 'Vodacom Blog'}
        </title>
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta
          name='google-site-verification'
          content='8iRkog4syZoPU8w-pOrnIZZ5wdRikoJwjbeuxdWoI64'
        />
        <meta name='theme-color' content='#ffffff' />
        <meta name='color-scheme' content='light' />
        <Script defer dangerouslySetInnerHTML={{ __html: TWQ }} />
      </Head>
      {meta_info &&
        meta_info.map((i, index) => (
          <Head key={index}>
            <meta name={i.name} content={i.content} />
          </Head>
        ))}
      {meta_info_og &&
        meta_info_og.map((i, index) => (
          <Head key={index}>
            <meta property={i.property} content={i.content} />
          </Head>
        ))}
      <Slides carousel={carousel.list} />
      {featured_article && <FeaturedArticles {...featured_article} />}
      {show_category && articleListing && articleListing[0].category && (
        <h1 className='categoryTitle'>{articleListing[0].category[0]}</h1>
      )}
      <div className={s.Container}>
        {checkPath.includes('search') && <h1>Search results</h1>}
        {articleListing ? (
          <IsotopeContainer>
            {articleListing.length > 0 &&
              articleListing.map((item, index) => {
                return (
                  <IsotopeBlock id={`post_${index}`} key={`post_${index}`}>
                    <MasonryLayout
                      item={item}
                      key={`content_item_${index}`}
                      location={router.pathname}
                    />
                  </IsotopeBlock>
                );
              })}
          </IsotopeContainer>
        ) : (
          <div className={s.NoResults}>No results found</div>
        )}
      </div>
    </div>
  );
}

export default ArticleListing;
